.bg-back {
    background-color: #f3f3f4;
    margin: auto;
    padding: 20px;
    height: 700px;
}

.status {
    text-align: center;
}

.paymentPanel {
    text-align: center;
    background: white;
    width: 300px;
    margin: auto;
    margin-top: 40px;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 1px 1px 9px 2px black;
}

.paymentPanel p {
    overflow: hidden;
    font-weight: bold;
}

.paymentPanel button {
    color: #fff;
    background-color: #1ab394;
    border-color: #1ab394;
    padding: 10px;
    border-radius: 5px;
    border: none;
}

.paymentDescription {
    font-size: 13px;
    color: #00CE1B;
    text-align: center;
}

.address {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
}

.coinAddress {
    width: 280px;
    height: 25px;
    font-size: 18px;
}

.centerImage {
    border-radius: 20%;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 300px;
    height: 300px;
}

.animationDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 460px;
}

@media only screen and (min-width : 500px) {
    .animationDiv {
        height: 520px !important;
    }
}